<template>
  <div class="completion-of-payment">
    <div>
      <p class="prosperity"><svg-icon icon-class="smiled" />恭喜付款成功</p>
      <p>
        支付金额：<span>{{ totalAmount }}元</span>
      </p>
      <p>订单编号：{{ $route.query.orderNum }}</p>
      <p>
        您还可以：<a href="#/home">继续购物</a
        ><a href="#/PersonalCenter">查看订单详情</a>
      </p>
    </div>
  </div>
</template>

<script>
import { decimals } from "@/utils/validate";
export default {
  name: "completion-of-payment",

  data() {
    return {};
  },
  created() {
    this.totalAmount = decimals(Number(sessionStorage.getItem("totalAmount")));
    // this.indent()
  },
  beforeDestroy() {
    
    sessionStorage.removeItem("orderNum");
  },
  methods: {
    // async indent() {
    //   const data = await this.$API.personalUser
    //     .TransactionOrder({
    //       object: this.orderInformation,
    //     })
    //     .then((res) => {
    //       Cookies.remove("oder");
    //     });
    // },
    // const data = await this.$API.personalUser.TransactionOrder({
    //   object: message,
    // });
  },
};
</script>

<style lang="scss" scoped>
.completion-of-payment {
  width: 1200px;
  height: 390px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
  padding: 60px 0;
  div {
    padding-left: 44px;
    .prosperity {
      height: 32px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #00c45c;
      position: relative;
      .svg-icon {
        position: absolute;
        top: 0;
        left: -44px;
        font-size: 32px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      span {
        color: #fe3132;
      }
      a {
        color: #2288ee;
        margin-right: 24px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    p:nth-child(2) {
      margin: 18px 0 6px 0;
    }
    p:nth-child(3) {
      margin-bottom: 18px;
    }
  }
}
</style>